module.exports = [{
      plugin: require('/Users/greghurrell/code/alloy-editor/website/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/greghurrell/code/alloy-editor/website/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/greghurrell/code/alloy-editor/website/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[],"globPatterns":["**/*.{js,jpg,png,gif,html,css,svg}"]},
    },{
      plugin: require('/Users/greghurrell/code/alloy-editor/website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
