// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/greghurrell/code/alloy-editor/website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---gatsby-cache-gatsby-mdx-mdx-wrappers-dir-a-774303-a-95098-a-9-f-8-c-85-b-08340284378-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/Users/greghurrell/code/alloy-editor/website/gatsby/.cache/gatsby-mdx/mdx-wrappers-dir/a774303a95098a9f8c85b08340284378--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---gatsby-cache-gatsby-mdx-mdx-wrappers-dir-a-774303-a-95098-a-9-f-8-c-85-b-08340284378-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---gatsby-cache-gatsby-mdx-mdx-wrappers-dir-afcdf-3-ec-318-f-6-a-7-c-9-b-8927-b-17937-c-17-c-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/Users/greghurrell/code/alloy-editor/website/gatsby/.cache/gatsby-mdx/mdx-wrappers-dir/afcdf3ec318f6a7c9b8927b17937c17c--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---gatsby-cache-gatsby-mdx-mdx-wrappers-dir-afcdf-3-ec-318-f-6-a-7-c-9-b-8927-b-17937-c-17-c-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---src-pages-404-js": () => import("/Users/greghurrell/code/alloy-editor/website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/greghurrell/code/alloy-editor/website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/Users/greghurrell/code/alloy-editor/website/.cache/data.json")

